import { Component, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfUser from "@/vue/domain/user/df-user";
import DfStore from "@/vue/domain/store/df-store";

@Component({
  components: {
    DfFormLogin: () => Utils.externalComponent2("df-form-login"),
    DfFormRegistration: () => Utils.externalComponent2("df-form-manager"),
    DfFormPasswordForgotten: () => Utils.externalComponent2("df-form-password-forgotten"),
    DfFormPasswordChange: () => Utils.externalComponent2("df-form-password-change"),
    DfFormEmailChange: () => Utils.externalComponent2("df-form-email-change"),
    DfFormDeleteAccount: () => Utils.externalComponent2("df-form-delete-account"),
  },
})
export default class DfPrivateAreaComponent extends Vue {
  private selectTab = "login";

  get user(): DfUser {
    return this.$store.getters.user;
  }

  get userFirstname(): string {
    return Utils.getPropertyValue(this.user, "FIRST_NAME", "STRING");
  }

  get email(): string {
    return this.user ? this.user.email : "";
  }

  get isUserLogged(): boolean {
    return !!this.user;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get showLogin(): boolean {
    return this.$store.getters.showLogin;
  }

  set showLogin(showLogin: boolean) {
    this.$store.dispatch("setShowLogin", showLogin);
  }

  get showPrivateAreaCashback(): boolean {
    return Utils.showPrivateAreaCashback();
  }

  get showPrivateAreaCoupon(): boolean {
    return Utils.enableSaviCoupon() || Utils.enableLoyalGuruCoupon();
  }

  private showRegistrationForm() {
    this.$emit("close");
    if (this.$route.name !== "user-registration") {
      this.$router.push({ name: "user-registration", params: { storeAlias: this.currentStore.alias } });
    }
  }

  private userLogged() {
    this.showLogin = false;
    this.selectTab = "login";
    if (this.$store.getters.initialRoute) {
      this.$emit("close");
      if (this.$store.getters.initialRoute) {
        this.$router.push({
          name: (this.$store.getters.initialRoute.meta || {}).redirectTo || this.$store.getters.initialRoute.name || "home-store",
        });
        this.$store.dispatch("setInitialRoute", null);
      }
    }
  }

  private showForgottenPassword() {
    this.selectTab = "forgottenPassword";
  }

  private showPasswordChangeForm() {
    this.selectTab = "changePassword";
  }

  private showEmailChangeForm() {
    this.selectTab = "changeEmail";
  }

  private showDeleteAccount() {
    this.selectTab = "deleteAccount";
  }

  private logout() {
    this.selectTab = "login";
    this.$root.$emit("logout", () => {
      this.$root.$emit("login", () => {
        if (this.$route.name !== "home-store") {
          this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
        }
      });
    });
    this.$emit("close");
  }

  private recoveredPassword() {
    this.$emit("close");
  }

  private changedPassword() {
    this.selectTab = "personal-area";
    this.$emit("close");
  }

  private changedEmail() {
    this.selectTab = "personal-area";
    this.$emit("close");
  }

  private deleteAccount() {
    this.logout();
  }

  private goToChangeProfile() {
    this.selectTab = "login";
    this.$emit("close");
    if (this.$route.name !== "user-profile-edit") {
      this.$router.push({ name: "user-profile-edit" });
    }
  }

  private goToAccountCard() {
    this.selectTab = "login";
    this.$emit("close");
    if (this.$route.name !== "account-card") {
      this.$router.push({ name: "account-card" });
    }
  }

  private goToAccountCashback() {
    this.selectTab = "login";
    this.$emit("close");
    if (this.$route.name !== "account-cashback") {
      this.$router.push({ name: "account-cashback" });
    }
  }

  private goToAccountExpenses() {
    this.selectTab = "login";
    this.$emit("close");
    if (this.$route.name !== "account-expenses") {
      this.$router.push({ name: "account-expenses" });
    }
  }

  private goToAccountCoupon() {
    this.selectTab = "login";
    this.$emit("close");
    if (this.$route.name !== "account-coupon") {
      this.$router.push({ name: "account-coupon" });
    }
  }
}
